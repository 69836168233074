import gql from 'graphql-tag';

export default gql`
query getAllPagesOfProjectBySlugPath($slugPath: String!) {
  item_lookup(where: {slug_path: {_eq: $slugPath}}) {
    project {
      child_pages {
        name
        slug
      }
    }
  }
}
`;
