<template>
  <b-row class="mb-4">
    <b-col cols="12" offset-sm="1" sm="3">
      <label>{{ label }}</label>
    </b-col>
    <b-col cols="12" sm="7">
      <div class="mb-2">
        <b-form-checkbox
          :name="handle + '_activate'"
          size="lg"
          v-model="activate"
          @input="updateField($event)"
        >
        {{ activateLabel }}
      </b-form-checkbox>
        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
        <b-form-text v-html="activateDesc"></b-form-text>
      </div>
      <div v-if="activate">
        <div class="mb-2">
          <b-form-input
            :name="handle + '_url'"
            v-model="url"
            :placeholder="urlPlaceholder"
            debounce="500"
            @update="updateField"
          ></b-form-input>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <b-form-text v-html="urlDesc"></b-form-text>
        </div>
        <div class="mb-2">
          <b-form-input
            :name="handle + '_text'"
            v-model="text"
            :placeholder="textPlaceholder"
            debounce="500"
            @update="updateField"
          ></b-form-input>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <b-form-text v-if="desc" v-html="desc"></b-form-text>
        </div>
        <div class="mb-2">
          <b-form-checkbox
            :name="handle + '_showOnMobile'"
            size="lg"
            v-model="showOnMobile"
            @input="updateField"
          >
          {{ showOnMobileLabel }}
        </b-form-checkbox>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <b-form-text v-html="showOnMobileDesc"></b-form-text>
        </div>
        <div class="mb-2">
          <b-form-checkbox
            :name="handle + '_openInNewTab'"
            size="lg"
            v-model="openInNewTab"
            @input="updateField($event)"
          >
          {{ openInNewTabLabel }}
        </b-form-checkbox>
          <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
          <b-form-text v-html="openInNewTabDesc"></b-form-text>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CustomButtonRow',
  props: {
    valueFromDb: { type: Object, default: null },
    handle: { type: String, default: null },
    label: { type: String, default: null },
    desc: { type: String, default: null },
  },
  data() {
    return {
      activate: false,
      activateLabel: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.activate'),
      activateDesc: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.activateDesc'),
      url: null,
      urlPlaceholder: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.url'),
      urlDesc: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.urlDesc'),
      text: null,
      textDesc: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.textDesc'),
      textPlaceholder: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.textPlaceholder'),
      showOnMobile: false,
      showOnMobileLabel: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.showOnMobile'),
      showOnMobileDesc: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.showOnMobileDesc'),
      openInNewTab: false,
      openInNewTabLabel: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.openInNewTab'),
      openInNewTabDesc: this.$t('settings.project.projectType.themeSetting.navigation.custom_button.openInNewTabDesc'),
    };
  },
  created() {
    if (this.valueFromDb) {
      this.activate = !!this.valueFromDb.activate;
      this.url = this.valueFromDb.url;
      this.text = this.valueFromDb.text;
      this.showOnMobile = this.valueFromDb.showOnMobile;
      this.openInNewTab = this.valueFromDb.openInNewTab;
    }
  },
  methods: {
    updateField() {
      const button = {
        activate: this.activate,
        url: this.url,
        text: this.text,
        showOnMobile: this.showOnMobile,
        openInNewTab: this.openInNewTab,
      };
      this.$emit('update-field', button);
    },
  },
};
</script>
