<template>
  <div class="pt-4">
    <b-row
      v-for="(themeConfigSection, sectionIndex) in themeFieldConfig"
      :key="`sec${sectionIndex}`"
      class="mb-4"
    >
      <b-col cols="12" offset-sm="1" sm="10" class="sticky-title">
        <h3>
          {{ themeConfigSection.title }}
        </h3>
        <hr>
      </b-col>
      <b-col>
        <div
          v-for="(themeConfigItem, index) in themeConfigSection.fields"
          :key="index"
        >
          <component
            v-if="showComponent(themeConfigItem)"
            :is="themeConfigItem.fieldType"
            :handle="themeConfigItem.handle"
            :label="themeConfigItem.title"
            :desc="themeConfigItem.desc"
            :checkboxLabel="themeConfigItem.checkboxLabel"
            :value-from-db="getThemeValue(themeConfigSection.handle, themeConfigItem.handle)"
            :margin-bottom="themeConfigItem.marginBottom"
            @update-field="updateSettingValue($event, themeConfigItem.handle, themeConfigSection.handle)"
            @file-removed="updateSettingValue($event, themeConfigItem.handle, themeConfigSection.handle)"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import CheckboxRowWithLabel from '@/components/helper/CheckboxRowWithLabel.vue';
import ColorPickerRow from '@/components/helper/ColorPickerRow.vue';
import InputRowNoLabel from '@/components/helper/InputRowNoLabel.vue';
import InputRow from '@/components/helper/InputRow.vue';
import NavigationLinks from '@/components/helper/NavigationLinks.vue';
import CustomButtonRow from '@/components/helper/CustomButtonRow.vue';

export default {
  name: 'ThemeSettingsDirectTop2',
  components: {
    CheckboxRow,
    CheckboxRowWithLabel,
    ImageUploadRow,
    InputRowNoLabel,
    InputRow,
    ColorPickerRow,
    NavigationLinks,
    CustomButtonRow,
  },
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      themeFieldConfig: [
        {
          title: this.$t('settings.project.projectType.themeSetting.navigation.menu.title'),
          handle: 'menu',
          fields: [
            {
              handle: 'logoimage',
              title: this.$t('settings.project.projectType.themeSetting.navigation.logoimage.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.logoimage.desc'),
              fieldType: 'ImageUploadRow',
            },
            {
              handle: 'show_logo',
              title: this.$t('settings.project.projectType.themeSetting.navigation.show_logo.title'),
              desc: null,
              fieldType: 'CheckboxRow',
            },
            {
              handle: 'title',
              title: this.$t('settings.project.projectType.themeSetting.navigation.title.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.title.desc'),
              fieldType: 'InputRow',
            },
            {
              handle: 'article',
              title: this.$t('settings.project.projectType.themeSetting.navigation.article.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.article.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.article.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'pagination',
              title: this.$t('settings.project.projectType.themeSetting.navigation.pagination.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.pagination.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.pagination.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'shadow',
              title: this.$t('settings.project.projectType.themeSetting.navigation.shadow.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.shadow.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.shadow.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'show_borders',
              title: this.$t('settings.project.projectType.themeSetting.navigation.show_borders.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.show_borders.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.show_borders.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'background_color',
              title: this.$t('settings.project.projectType.themeSetting.navigation.background_color.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.background_color.desc'),
              fieldType: 'ColorPickerRow',
            },
            {
              handle: 'color',
              title: this.$t('settings.project.projectType.themeSetting.navigation.color.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.color.desc'),
              fieldType: 'ColorPickerRow',
            },
            {
              handle: 'scrollMenu',
              title: this.$t('settings.project.projectType.themeSetting.navigation.scrollMenu.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.scrollMenu.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.scrollMenu.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'menuTags',
              title: this.$t('settings.project.projectType.themeSetting.navigation.menuTags.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.menuTags.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.menuTags.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'hideNavOnScroll',
              title: this.$t('settings.project.projectType.themeSetting.navigation.hideNavOnScroll.title'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.hideNavOnScroll.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              marginBottom: 'mb-0',
            },
            {
              handle: 'hideNavScrollOnlyOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.hideNavScrollOnlyOnMobile.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.hideNavScrollOnlyOnMobile.desc'),
              fieldType: 'CheckboxRow',
            },
          ],
        },
        {
          title: 'Menu Buttons',
          handle: 'menuButtons',
          fields: [
            {
              handle: 'mobile_dropdown',
              title: this.$t('settings.project.projectType.themeSetting.navigation.mobile_dropdown.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.mobile_dropdown.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.mobile_dropdown.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'activate_search',
              title: this.$t('settings.project.projectType.themeSetting.navigation.search.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.search.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'search_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.search.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_search',
            },
            {
              handle: 'search_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.search_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.search_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_search',
            },
            {
              handle: 'activate_cookies',
              title: this.$t('settings.project.projectType.themeSetting.navigation.cookies.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.cookies.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'cookies_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.cookies.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_cookies',
            },
            {
              handle: 'cookies_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.cookies_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.cookies_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_cookies',
            },
            {
              handle: 'activate_sharing',
              title: this.$t('settings.project.projectType.themeSetting.navigation.sharing.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.sharing.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'sharing_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.sharing.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_sharing',
            },
            {
              handle: 'sharing_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.sharing_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.sharing_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_sharing',
            },

            {
              handle: 'activate_facebook',
              title: this.$t('settings.project.projectType.themeSetting.navigation.facebook.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.facebook.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'facebook_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.facebook.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_facebook',
            },
            {
              handle: 'facebook',
              title: this.$t('settings.project.projectType.themeSetting.navigation.facebook.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.facebook.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_facebook',
            },
            {
              handle: 'facebook_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.facebook_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.facebook_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_facebook',
            },
            {
              handle: 'activate_instagram',
              title: this.$t('settings.project.projectType.themeSetting.navigation.instagram.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.instagram.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'instagram_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.instagram.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_instagram',
            },
            {
              handle: 'instagram',
              title: this.$t('settings.project.projectType.themeSetting.navigation.instagram.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.instagram.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_instagram',
            },
            {
              handle: 'instagram_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.instagram_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.instagram_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_instagram',
            },
            {
              handle: 'activate_linkedin',
              title: this.$t('settings.project.projectType.themeSetting.navigation.linkedin.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.linkedin.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'linkedin_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.linkedin.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_linkedin',
            },
            {
              handle: 'linkedin',
              title: this.$t('settings.project.projectType.themeSetting.navigation.linkedin.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.linkedin.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_linkedin',
            },
            {
              handle: 'linkedin_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.linkedin_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.linkedin_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_linkedin',
            },
            {
              handle: 'activate_twitter',
              title: this.$t('settings.project.projectType.themeSetting.navigation.twitter.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.twitter.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'twitter_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.twitter.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_twitter',
            },
            {
              handle: 'twitter',
              title: this.$t('settings.project.projectType.themeSetting.navigation.twitter.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.twitter.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_twitter',
            },
            {
              handle: 'twitter_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.twitter_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.twitter_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_twitter',
            },
            {
              handle: 'activate_xing',
              title: this.$t('settings.project.projectType.themeSetting.navigation.xing.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.xing.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'xing_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.xing.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_xing',
            },
            {
              handle: 'xing',
              title: this.$t('settings.project.projectType.themeSetting.navigation.xing.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.xing.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_xing',
            },
            {
              handle: 'xing_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.xing_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.xing_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_xing',
            },
            {
              handle: 'activate_youtube',
              title: this.$t('settings.project.projectType.themeSetting.navigation.youtube.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.youtube.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'youtube_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.youtube.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_youtube',
            },
            {
              handle: 'youtube',
              title: this.$t('settings.project.projectType.themeSetting.navigation.youtube.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.youtube.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_youtube',
            },
            {
              handle: 'youtube_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.youtube_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.youtube_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_youtube',
            },
            {
              handle: 'activate_website',
              title: this.$t('settings.project.projectType.themeSetting.navigation.website.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.website.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'website_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.website.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_website',
            },
            {
              handle: 'website',
              title: this.$t('settings.project.projectType.themeSetting.navigation.website.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.website.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_website',
            },
            {
              handle: 'website_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.website_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.website_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_website',
            },
            {
              handle: 'activate_print',
              title: this.$t('settings.project.projectType.themeSetting.navigation.print.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.print.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'print_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.print.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_print',
            },
            {
              handle: 'print',
              title: this.$t('settings.project.projectType.themeSetting.navigation.print.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.print.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_print',
            },
            {
              handle: 'print_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.print_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.print_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_print',
            },
            {
              handle: 'activate_overview',
              title: this.$t('settings.project.projectType.themeSetting.navigation.overview.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.overview.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'overview_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.overview.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_overview',
            },
            {
              handle: 'overview',
              title: this.$t('settings.project.projectType.themeSetting.navigation.overview.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.overview.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_overview',
            },
            {
              handle: 'overview_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.overview_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.overview_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_overview',
            },
            {
              handle: 'activate_imprint',
              title: this.$t('settings.project.projectType.themeSetting.navigation.imprint.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.imprint.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'imprint_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.imprint.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_imprint',
            },
            {
              handle: 'imprint',
              title: this.$t('settings.project.projectType.themeSetting.navigation.imprint.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.imprint.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_imprint',
            },
            {
              handle: 'imprint_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.imprint_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.imprint_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_imprint',
            },
            {
              handle: 'activate_dataprivacy',
              title: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'dataprivacy_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_dataprivacy',
            },
            {
              handle: 'dataprivacy',
              title: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_dataprivacy',
            },
            {
              handle: 'dataprivacy_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.dataprivacy_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_dataprivacy',
            },
            {
              handle: 'activate_custom1',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.custom1.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'custom1_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_custom1',
            },
            {
              handle: 'custom1',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom1.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom1',
            },
            {
              handle: 'custom1_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom1_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom1',
            },
            {
              handle: 'custom1_icon',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1_icon.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom1_icon.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom1',
            },
            {
              handle: 'custom1_openInNewTab',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom1_openInNewTab.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom1_openInNewTab.desc'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_custom1',
            },
            {
              handle: 'activate_custom2',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2.title'),
              desc: null,
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.custom2.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'custom2_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_custom2',
            },
            {
              handle: 'custom2',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2.urlText'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom2.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom2',
            },
            {
              handle: 'custom2_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom2_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom2',
            },
            {
              handle: 'custom2_icon',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2_icon.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom2_icon.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_custom2',
            },
            {
              handle: 'custom2_openInNewTab',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom2_openInNewTab.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.custom2_openInNewTab.desc'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_custom2',
            },
            {
              handle: 'custom_button_1',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom_button_1.title'),
              fieldType: 'CustomButtonRow',
            },
            {
              handle: 'custom_button_2',
              title: this.$t('settings.project.projectType.themeSetting.navigation.custom_button_2.title'),
              fieldType: 'CustomButtonRow',
            },
            {
              handle: 'activate_intro',
              title: this.$t('settings.project.projectType.themeSetting.navigation.intro.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.intro.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.intro.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'intro_showOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.intro.showOnMobile'),
              fieldType: 'CheckboxRow',
              showIfActive: 'menuButtons.activate_intro',
            },
            {
              handle: 'intro_tooltip',
              title: this.$t('settings.project.projectType.themeSetting.navigation.intro_tooltip.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.intro_tooltip.desc'),
              fieldType: 'InputRowNoLabel',
              showIfActive: 'menuButtons.activate_intro',
            },
          ],
        },
        {
          title: 'Side Menu',
          handle: 'sideMenu',
          fields: [
            {
              handle: 'menu_icon',
              title: this.$t('settings.project.projectType.themeSetting.navigation.menu_icon.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.menu_icon.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.menu_icon.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'menu_index',
              title: this.$t('settings.project.projectType.themeSetting.navigation.menu_index.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.menu_index.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.menu_index.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'sideMenu.menu_icon',
            },
          ],
        },
        {
          title: this.$t('settings.project.projectType.themeSetting.navigation.articleSettings.title'),
          handle: 'articleSettings',
          fields: [
            {
              handle: 'activate_width',
              title: this.$t('settings.project.projectType.themeSetting.navigation.activate_width.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.activate_width.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.activate_width.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'maxWidth',
              title: this.$t('settings.project.projectType.themeSetting.navigation.maxWidth.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.maxWidth.desc'),
              fieldType: 'InputRow',
              showIfActive: 'articleSettings.activate_width',
            },
            {
              handle: 'maxWidthBackgroundColor',
              title: this.$t('settings.project.projectType.themeSetting.navigation.maxWidthBackgroundColor.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.maxWidthBackgroundColor.desc'),
              fieldType: 'ColorPickerRow',
              showIfActive: 'articleSettings.activate_width',
            },
          ],
        },
        {
          title: this.$t('settings.project.projectType.themeSetting.navigation.navigation.title'),
          handle: 'navigationArrow',
          fields: [
            {
              handle: 'navigation',
              title: this.$t('settings.project.projectType.themeSetting.navigation.navigation.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.navigation.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.navigation.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'showNavigationOnMobile',
              title: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationOnMobile.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationOnMobile.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationOnMobile.activate'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationArrow.navigation',
            },
            {
              handle: 'navigation_hide',
              title: this.$t('settings.project.projectType.themeSetting.navigation.navigation_hide.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_hide.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.navigation_hide.activate'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationArrow.navigation',
            },
            {
              handle: 'navigation_position',
              title: this.$t('settings.project.projectType.themeSetting.navigation.navigation_position.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_position.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.navigation_position.activate'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationArrow.navigation',
            },
            {
              handle: 'showNavigationAtSides',
              title: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationAtSides.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationAtSides.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.showNavigationAtSides.activate'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationArrow.navigation',
            },
          ],
        },
        {
          title: 'Navigation Widget',
          handle: 'navigationWidget',
          fields: [
            {
              handle: 'activate_pagination',
              title: this.$t('settings.project.projectType.themeSetting.navigation.activate_pagination.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.activate_pagination.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.activate_pagination.activate'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'showPaginationDesktop',
              title: this.$t('settings.project.projectType.themeSetting.navigation.showPaginationDesktop.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.showPaginationDesktop.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.showPaginationDesktop.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationWidget.activate_pagination',
            },
            {
              handle: 'hidePaginationOnScroll',
              title: this.$t('settings.project.projectType.themeSetting.navigation.hidePaginationOnScroll.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.hidePaginationOnScroll.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.hidePaginationOnScroll.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'navigationWidget.activate_pagination',
            },
          ],
        },
        {
          title: 'Bottom Navigation Bar',
          handle: 'bottomNavigationBar',
          fields: [
            {
              handle: 'showBottomNavigationBar',
              title: this.$t('settings.project.projectType.themeSetting.navigation.showBottomNavigationBar.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.showBottomNavigationBar.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.showBottomNavigationBar.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
            },
            {
              handle: 'showOnDesktop',
              title: this.$t('settings.project.projectType.themeSetting.navigation.showOnDesktop.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.showOnDesktop.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.showOnDesktop.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'bottomNavigationBar.showBottomNavigationBar',
            },
            {
              handle: 'hideOnScroll',
              title: this.$t('settings.project.projectType.themeSetting.navigation.hideOnScroll.title'),
              desc: this.$t('settings.project.projectType.themeSetting.navigation.hideOnScroll.desc'),
              checkboxLabel: this.$t('settings.project.projectType.themeSetting.navigation.hideOnScroll.checkboxLabel'),
              fieldType: 'CheckboxRowWithLabel',
              showIfActive: 'bottomNavigationBar.showBottomNavigationBar',
            },
            {
              handle: 'data',
              title: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.title'),
              fieldType: 'NavigationLinks',
              showIfActive: 'bottomNavigationBar.showBottomNavigationBar',
            },
          ],
        },
      ],
      themeValues: {
        menu: {
          logoimage: {
            url: null,
            width: null,
            height: null,
            focalpoint: {
              x: 50,
              y: 50,
            },
          },
          show_logo: true,
          title: null,
          menu_icon: true,
          menu_index: true,
          article: false,
          pagination: false,
          shadow: true,
          show_borders: false,
          background_color: '#ffffff',
          color: '#202020',
          scrollMenu: false,
          menuTags: false,
          hideNavOnScroll: false,
          hideNavScrollOnlyOnMobile: false,
        },
        sideMenu: {
          menu_icon: true,
          menu_index: true,
        },
        menuButtons: {
          mobile_dropdown: true,

          activate_search: false,
          search_tooltip: null,
          search_showOnMobile: false,

          activate_cookies: false,
          cookies_tooltip: null,
          cookies_showOnMobile: false,

          activate_sharing: false,
          sharing_tooltip: null,
          sharing_showOnMobile: false,

          activate_intro: false,
          intro_tooltip: null,
          intro_showOnMobile: false,

          activate_facebook: false,
          facebook: null,
          facebook_tooltip: null,
          facebook_showOnMobile: false,

          activate_instagram: false,
          instagram: null,
          instagram_tooltip: null,
          instagram_showOnMobile: false,

          activate_linkedin: false,
          linkedin: null,
          linkedin_tooltip: null,
          linkedin_showOnMobile: false,

          activate_twitter: false,
          twitter: null,
          twitter_tooltip: null,
          twitter_showOnMobile: false,

          activate_xing: false,
          xing: null,
          xing_tooltip: null,
          xing_showOnMobile: false,

          activate_youtube: false,
          youtube: null,
          youtube_tooltip: null,
          youtube_showOnMobile: false,

          activate_website: false,
          website: null,
          website_tooltip: null,
          website_showOnMobile: false,

          activate_print: false,
          print: null,
          print_tooltip: null,
          print_showOnMobile: false,

          activate_overview: false,
          overview: null,
          overview_tooltip: null,
          overview_showOnMobile: false,

          activate_imprint: false,
          imprint: null,
          imprint_tooltip: null,
          imprint_showOnMobile: false,

          activate_dataprivacy: false,
          dataprivacy: null,
          dataprivacy_tooltip: null,
          dataprivacy_showOnMobile: false,

          activate_custom1: false,
          custom1: null,
          custom1_tooltip: null,
          custom1_icon: 'fa-heart',
          custom1_showOnMobile: false,
          custom1_openInNewTab: false,
          activate_custom2: false,
          custom2: null,
          custom2_tooltip: null,
          custom2_icon: 'fa-heart',
          custom2_showOnMobile: false,
          custom2_openInNewTab: false,

          custom_button_1: {
            activate: false,
            showOnMobile: false,
            openInNewTab: false,
            url: null,
            text: null,
          },
          custom_button_2: {
            activate: false,
            showOnMobile: false,
            openInNewTab: false,
            url: null,
            text: null,
          },
        },
        articleSettings: {
          activate_width: false,
          maxWidth: null,
          maxWidthBackgroundColor: null,
        },
        navigationArrow: {
          navigation: true,
          navigation_hide: false,
          navigation_position: false,
          showNavigationOnMobile: true,
          showNavigationAtSides: false,
        },
        navigationWidget: {
          activate_pagination: false, // here it is called pagination
          showPaginationDesktop: false,
          hidePaginationOnScroll: false,
        },
        bottomNavigationBar: {
          showBottomNavigationBar: false,
          showOnDesktop: false,
          hideOnScroll: false,
          topValueOnDesktop: 100,
          data: [],
        },
      },
    };
  },
  created() {
    // add the values to the data
    if (this.settingValues) {
      Object.entries(this.settingValues).forEach(([key, value]) => {
        if (this.themeValues.hasOwnProperty(key)) {
          if (typeof value === 'object' && value !== null) {
            // Handle nested objects by merging them
            this.themeValues[key] = {
              ...this.themeValues[key],
              ...value,
            };
          } else {
            // Handle primitive values
            this.themeValues[key] = value;
          }
        }
      });
    }
    this.$emit('update-theme-settings', this.themeValues);
  },
  methods: {
    showComponent(value) {
      if (value?.showIfActive) {
        const keys = value.showIfActive.split('.');
        // get the value from the themeValues
        const entryOfThemeValues = keys.reduce((acc, key) => ((acc && acc[key] !== undefined) ? acc[key] : undefined), this.themeValues);
        if (entryOfThemeValues === undefined) {
          return false;
        }
        return entryOfThemeValues;
      }
      return true;
    },
    getThemeValue(sectionHandle, itemHandle) {
      return this.themeValues[sectionHandle][itemHandle];
    },
    updateSettingValue(value, itemHandle, sectionHandle) {
      if (this.themeValues[sectionHandle]) {
        this.$set(this.themeValues[sectionHandle], itemHandle, value);
        this.$emit('update-theme-settings', this.themeValues);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-title {
  position: sticky;
    top: 66px;
    background: #44484c;
    z-index: 2;
}
</style>
