<template>
  <div>
    <b-row class="mb-4" v-for="(item, index) in data" :key="'cl-' + index">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.projectType.themeSetting.navigation.navigation_links.title', {number: index+1}) }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <div class="mb-2" v-if="index > 1">
          <b-form-checkbox
            v-model="data[index].isActive"
            size="lg"
            @change="updateField('checkbox', index, $event)"
          >
            {{ $t('settings.project.projectType.themeSetting.navigation.navigation_links.activate') }}
          </b-form-checkbox>
        </div>
        <div v-if="data[index].isActive">
          <div class="mb-3">
            <b-form-input
              :id="`name-${handle}-${index}`"
              v-model="data[index].name"
              :placeholder="namePlaceholder"
              debounce="500"
              @update="updateField('input', index, $event)"
            ></b-form-input>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <b-form-text v-html="nameDesc"></b-form-text>
          </div>
          <div class="mb-3">
            <b-form-select
              :id="`slug-${handle}-${index}`"
              v-model="data[index].slug"
              :options="articleOptions"
              @change="updateField('select', index, $event)"
            ></b-form-select>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <b-form-text v-html="articleDesc"></b-form-text>
          </div>
          <div class="mb-3">
            <b-form-input
              :id="`icon-${handle}-${index}`"
              v-model="data[index].icon"
              :placeholder="iconPlaceholder"
              debounce="500"
              @update="updateField('input', index, $event)"
            ></b-form-input>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <b-form-text v-html="iconDesc"></b-form-text>
          </div>
          <div class="mb-3">
            <b-form-textarea
              :id="`svgIcon-${handle}-${index}`"
              v-model="data[index].iconSvg"
              debounce="500"
              rows="6"
              max-rows="10"
              :placeholder="svgIconPlaceholder"
              @update="updateField('textarea', index, $event)"
            ></b-form-textarea>
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <b-form-text v-html="svgIconDesc"></b-form-text>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GetAllPagesOfProjectBySlugPath from '@/graphQlQueries/queries/getAllPagesOfProjectBySlugPath';

export default {
  name: 'NavigationLinks',
  props: {
    valueFromDb: { type: Array, default: null },
    handle: { type: String, default: null },
    label: { type: String, default: null },
  },
  computed: {
    articleOptions() {
      if (this.pages.length === 0) {
        return [
          { value: null, text: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.emptyPages') },
        ];
      }
      return [
        { value: null, text: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.selectPage') },
        ...this.pages.map((page) => ({ value: page.slug, text: page.name })),
      ];
    },
  },
  data() {
    return {
      namePlaceholder: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.name'),
      nameDesc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.nameDesc'),
      articleDesc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.articleDesc'),
      iconPlaceholder: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.icon'),
      iconDesc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.iconDesc'),
      svgIconPlaceholder: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.svgIconCode'),
      svgIconDesc: this.$t('settings.project.projectType.themeSetting.navigation.navigation_links.svgIconDesc'),
      pages: [],
      data: [
        {
          isActive: true,
          name: null,
          slug: null,
          color: '',
          icon: null,
          iconSvg: null,
        },
        {
          isActive: true,
          name: null,
          slug: null,
          color: '',
          icon: null,
          iconSvg: null,
        },
        {
          isActive: false,
          name: null,
          slug: null,
          color: '',
          icon: null,
          iconSvg: null,
        },
        {
          isActive: false,
          name: null,
          slug: null,
          color: '',
          icon: null,
          iconSvg: null,
        },
        {
          isActive: false,
          name: null,
          slug: null,
          color: '',
          icon: null,
          iconSvg: null,
        },
      ],
    };
  },
  apollo: {
    pages: {
      query: GetAllPagesOfProjectBySlugPath,
      variables() {
        return {
          slugPath: `/${this.$route.params.parametersOfUrl}`,
        };
      },
      update(data) {
        if (data?.item_lookup?.[0]?.project?.child_pages) {
          return data.item_lookup[0].project.child_pages;
        }
        return [];
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.$route.params.parametersOfUrl;
      },
    },
  },
  created() {
    if (this.valueFromDb) {
      this.data = this.data.map((item, index) => ({
        ...item,
        ...this.valueFromDb[index],
      }));
    }
  },
  methods: {
    updateField(type, index, value) {
      if (type === 'select' && this.data[index].name === null) {
        const selectedPage = this.pages.find((page) => page.slug === value);
        if (selectedPage) {
          // change the name of the item
          this.data[index].name = selectedPage.name;
        }
      }
      this.$emit('update-field', this.data);
    },
  },
};
</script>
